import React, {
  useState,
  useEffect,
  useContext,
  Fragment,
  // useLayoutEffect,
} from "react";
import "./Navbar.css";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import CheckIcon from "@mui/icons-material/Check";

import CompanyListModal from "../../components/CompanyListModal/CompanyListModal";
import EditProfileModal from "../../components/EditProfileModal/EditProfileModal";
import { Link } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Avatar,
  AppBar,
  Box,
  Container,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Button,
  Divider,
  Modal,
  // Grid,
  // FormGroup,
  // TextareaAutosize,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {
  ArrowDropDown as ArrowDropDownIcon,
  // Business as BusinessIcon,
  Edit as EditIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import UpdateIcon from "@mui/icons-material/Update";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WatchIcon from "@mui/icons-material/Watch";
import { AppContext } from "../../context/appContext";
import { withRouter } from "react-router-dom";
import { isEqual } from "lodash";
import { titleCase } from "change-case-all";
import { capitalCase } from "capital-case";
import UpdateLearner from "../UpdateLearner/UpdateLearner";
import jwtDecode from "jwt-decode";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import MessageIcon from "@mui/icons-material/Message";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import HelpAndSupportModal from "../HelpAndSupportModal/HelpAndSupportModal";
import LearnerInfoModal from "../LearnerInfoModal/LearnerInfoModal";
import LearnerTrainingModal from "../LearnerTrainingModal/LearnerTrainingModal";

const textArea = {
  outline: "none",
  minWidth: 500,
  maxWidth: "100%",
  overflow: "auto !important",
  maxHeight: 300,
  padding: 10,
  resize: "none",
  marginBottom: 20,
  borderRadius: 4,
};

const layout = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "95vw",
  minWidth: "50vw",
  maxHeight: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const NavBar = (props) => {
  const location = useLocation();
  const history = useHistory();
  const isSpecificPage = location.pathname === "/sign-up";
  // data, setData, handleToggle, updateMessage, getUserData
  const { data, handleToggle, updateMessage } = useContext(AppContext);
  const [l, setAnchorEl] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [companies, setCompanies] = useState("");
  const [openUpdateLearnerModal, setOpenUpdateLearnerModal] = useState(false);
  const [openSupportModal, setOpenSupportModal] = useState(false);
  const [showLearnerInfoModal, setShowLearnerInfoModal] = useState(false);
  const [showLearnerTrainingModal, setShowLearnerTrainingModal] =
    useState(false);
  const [learnerData, setLearnerData] = useState();
  const [backdropOpen, setBackdropOpen] = useState(false);
  // const [supportText, setSupportText] = useState("");
  // const classes = useStyles();
  const getData = async () => {
    try {
      const response = await axios.get("learner/profile", {
        config: { handlerEnabled: true },
      });
      console.log("API response:", response);
      setLearnerData(response.data.data);
    } catch (e) {
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };
  useEffect(() => {
    if (showLearnerTrainingModal) {
      getData();
    }
  }, [showLearnerTrainingModal]);
  console.log("newlearner", learnerData);

  const [tempLearner, setTempLearner] = useState({
    imageUrl: "",
    uln: "",
    name: "",
    email: "",
    trainingProviderName: "",
    employerName: "",
    coach: "",
    manager: "",
    mentor: "",
    visitor: "",
    trainer: "",
    apprenticeshipStandard: "",
    coachMentor: [],
    emailToMentor: true,
    emailToCoach: true,
    status: "",
    _id: "",
    active: true,
  });

  const updateLearnerModal = (learner) => {
    setTempLearner({
      imageUrl: learner.imageUrl,
      uln: learner.uln,
      name: learner.name,
      email: learner.email,
      trainingProviderName: learner.trainingProviderName,
      employerName: learner.employerName,
      coach:
        learner?.coachMentor?.filter((faculty) => faculty?.role === "coach")[0]
          ?.email || "",
      manager:
        learner?.coachMentor?.filter(
          (faculty) => faculty?.role === "manager"
        )[0]?.email || "",
      mentor:
        learner?.coachMentor?.filter((faculty) => faculty?.role === "mentor")[0]
          ?.email || "",
      visitor:
        learner?.coachMentor?.filter(
          (faculty) => faculty?.role === "visitor"
        )[0]?.email || "",
      trainer:
        learner?.coachMentor?.filter(
          (faculty) => faculty?.role === "trainer"
        )[0]?.email || "",
      coachMentor: learner.coachMentor,
      apprenticeshipStandard: learner.apprenticeshipStandard,
      emailToMentor: false,
      emailToCoach: false,
      status: learner.status,
      active: learner.active,
    });

    setOpenUpdateLearnerModal(!openUpdateLearnerModal);
  };

  const newLearnerValidations = {
    name: false,
    email: false,
    trainingProviderName: false,
    employerName: false,
    apprenticeshipStandard: false,
    startDate: false,
    endDate: false,
    durationInMonths: false,
    durationInHours: false,
    trainingPercentage: false,
    targetMonths: false,
  };
  // To set the title of the pages
  useEffect(() => {
    if (data) {
      let pageName = props.location.pathname.split("/")[1];
      pageName = pageName != "admin" ? pageName : data.attribute;

      document.title = capitalCase(pageName, "PascalCase");
    }
  }, [data, props.location]);

  const handleMenu = (event) => {
    setBackdropOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setBackdropOpen(false);
    setAnchorEl(null);
  };

  // console.log(location.pathname);
  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload(false);
  };

  // dropdown logic
  const [showUpdateOptions, setShowUpdateOptions] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  const handleUpdatePlanClick = (event) => {
    // Stop the menu from closing when clicking on "Update Plan"
    event.stopPropagation();
    setShowUpdateOptions(!showUpdateOptions);
  };

  // const handleOptionSelect = (option) => {
  //   setSelectedPlan(option); // Update the selected plan
  //   handleClose(); // Close the menu after selection
  // };
  const handleOptionSelect = async (option) => {
    setSelectedPlan(option); // Update the selected plan
    handleClose(); // Close the menu after selection
    await redirectToStripe(option); // Call redirectToStripe with the selected plan as an argument
  };

  // To Redirect to Stripe For payment
  const redirectToStripe = async () => {
    let token = localStorage.getItem("7-star-training");
    let decodedToken = jwtDecode(token.replace("Bearer ", "")); // decode your token here

    try {
      handleToggle(true);
      await axios
        .post(
          "saas/billing",
          { id: decodedToken.id },
          {
            config: { handlerEnabled: true },
          }
        )
        .then((response) => {
          handleToggle(false);
          window.location.href = response.data.data.url;
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  const deleteLearner = async (id) => {
    try {
      handleToggle(true);
      await axios
        .delete(
          `learner/user/${id}`,
          // { id: decodedToken.id },
          {
            config: { handlerEnabled: true },
          }
        )
        .then((response) => {
          setAnchorEl(null);
          closeDeleteModal();
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload(false);
          handleToggle(false);
          // console.log("res", response);
          updateMessage(response.data.message);
          history.push("/");
          // window.location.href = response.data.data.url;
        });
    } catch (e) {
      handleToggle(false);
      if (e.response && e.response.data) {
        updateMessage(e.response.data.message);
      }
    }
  };

  const isDesktop = window.innerWidth > 899 ? true : false;

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  // console.log(data);

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ background: "#2D2C40" }}>
          <Container component="main" maxWidth="xl">
            <Toolbar
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Link to={"/"}>
                {location?.pathname?.includes("learner") ? (
                  <img src="/logo-icon.png" alt="" className="headerLogoIcon" />
                ) : (
                  <img src="/image.png" alt="" className="headerLogo" />
                )}
              </Link>
              <Typography
                variant="h5"
                component="h3"
                sx={{ flexGrow: 1 }}
              ></Typography>
              {data ? (
                <div className="headerProfileArea">
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    {/* <Avatar
                      alt={data.name}
                      src={data.imageUrl}
                      sx={{ width: 46, height: 46 }}
                    /> */}
                    <Typography style={{ paddingLeft: "10px" }}>
                      {/* {data.name} */}
                      <Avatar
                        alt={data.name}
                        src={data.imageUrl}
                        sx={{
                          width: 46,
                          height: 46,
                          border: `2px solid ${(
                            <Avatar
                              alt={data.name}
                              src={data.imageUrl}
                              sx={{
                                width: 46,
                                height: 46,
                                border: `2px solid ${
                                  data.targetStatus === "Below"
                                    ? "rgba(232, 5, 35, 0.08)"
                                    : data.targetStatus === "On"
                                    ? "rgba(0, 128, 0, 0.08)"
                                    : "rgba(0, 128, 0, 0.08)" // Fallback color if needed
                                }`,
                              }}
                            />
                          )}`,
                        }}
                      />
                    </Typography>
                    <ArrowDropDownIcon />
                  </IconButton>
                  {/* backdrop */}
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={backdropOpen}
                    onClick={handleClose}
                  >
                    <Menu
                      id="menu-appbar"
                      l={l}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      style={{
                        marginTop: 50,
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(l)}
                      onClose={handleClose}
                    >
                      <MenuItem>
                        <ListItemIcon>
                          <Avatar
                            alt={data.name}
                            src={data.imageUrl}
                            sx={{ width: 46, height: 46 }}
                          />
                        </ListItemIcon>
                        <div style={{ display: "flex" }}>
                          <div>
                            <Typography
                              style={{
                                paddingLeft: "10px",
                                fontSize: 15,
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                width: "120px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {data.name}
                            </Typography>
                            <Typography
                              style={{
                                textTransform: "capitalize",
                                paddingLeft: "10px",
                                fontSize: 10,
                              }}
                            >
                              {data.attribute ? data.attribute : "Learner"}
                            </Typography>
                          </div>
                          <IconButton
                            onClick={() => {
                              handleClose();
                              setOpenUpdateLearnerModal(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </div>
                      </MenuItem>

                      <hr />
                      <MenuItem
                        style={{ paddingLeft: 35 }}
                        onClick={() =>
                          setShowLearnerInfoModal(!showLearnerInfoModal)
                        }
                      >
                        <ListItemIcon>
                          <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        My Profile
                      </MenuItem>
                      <Divider />

                      <MenuItem
                        style={{ paddingLeft: 35 }}
                        // onClick={redirectToStripe}
                        onClick={() =>
                          setShowLearnerTrainingModal(!showLearnerTrainingModal)
                        }
                      >
                        <ListItemIcon>
                          <WatchIcon fontSize="small" />
                        </ListItemIcon>
                        Off-The-Job Hours
                      </MenuItem>
                      <Divider />

                      {data.subscription.plan && (
                        <>
                          <MenuItem
                            style={{ paddingLeft: 35 }}
                            onClick={redirectToStripe}
                          >
                            <ListItemIcon>
                              <UpdateIcon fontSize="small" />
                            </ListItemIcon>
                            Update/Cancel Plan ({data.subscription.plan})
                          </MenuItem>
                          <Divider />
                        </>
                      )}
                      {/* dropdown */}

                      {/* dropdown end */}

                      <MenuItem
                        style={{ paddingLeft: 35 }}
                        onClick={() => setDeleteModal(true)}
                      >
                        <ListItemIcon>
                          <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        Delete My Account
                      </MenuItem>
                      <Divider />

                      <MenuItem
                        style={{ paddingLeft: 35 }}
                        onClick={() => setOpenSupportModal(!openSupportModal)}
                      >
                        <ListItemIcon>
                          <MessageIcon fontSize="small" />
                        </ListItemIcon>
                        Send A Message
                      </MenuItem>
                      <Divider />

                      <MenuItem style={{ paddingLeft: 35 }} onClick={logOut}>
                        <ListItemIcon>
                          <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu>
                  </Backdrop>
                </div>
              ) : (
                isSpecificPage && (
                  <Typography sx={{ color: "white" }}>
                    {isDesktop && "Already Have an Account"}&nbsp;
                    {/* <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#5335ca",
                        "&:hover": {
                          backgroundColor: "#4b27dd",
                        },
                      }}
                    >
                      <RouterLink
                        to="/"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Sign in 
                      </RouterLink>
                    </Button> */}
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#5335ca",
                          "&:hover": {
                            backgroundColor: "#4b27dd",
                          },
                          color: "white",
                        }}
                      >
                        Sign in
                      </Button>
                    </Link>
                  </Typography>
                )
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </Box>

      <CompanyListModal
        open={showCompanyModal}
        close={() => setShowCompanyModal(false)}
        companies={companies}
      />

      <EditProfileModal
        open={showEditProfileModal}
        close={() => setShowEditProfileModal(false)}
      />
      <UpdateLearner
        open={openUpdateLearnerModal}
        close={() => setOpenUpdateLearnerModal(false)}
        tempLearner={tempLearner}
        setTempLearner={setTempLearner}
        newLearnerValidations={newLearnerValidations}
      />
      <ConfirmationModal
        open={deleteModal}
        close={closeDeleteModal}
        title="Confirmation"
        text="Are you sure you want to DELETE your account? Your Learner Portal and all your data in our database will also be deleted, and your subscription plan terminated."
        ctaTitleText="Delete"
        cta={() => deleteLearner(data._id)}
      />

      <Modal
        open={openSupportModal}
        onClose={() => setOpenSupportModal(!openSupportModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "scroll" }}
      >
        {/* <Box sx={layout} className="modalContainer">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="modalHeader"
          >
            <Typography id="modal-modal-title" variant="h5" component="h3" component="h2">
              Send a message
            </Typography>
            <CloseIcon
              title="Close"
              onClick={() => setOpenSupportModal(!openSupportModal)}
              sx={{
                height: 14,
                widht: 14,
                color: "#5335CA",
                cursor: "pointer",
                zIndex: 10,
              }}
            />
          </Grid>
          <FormGroup sx={{ paddingTop: 3 }}>
            <TextareaAutosize
              aria-label="helpMessage textarea"
              minRows={4}
              placeholder="Message..."
              label="Work Email"
              // className="helpTextArea"
              className={`helpTextArea ${textArea}`}
              name="helpMessage"
              value={supportText}
              onChange={(e) => setSupportText(e.target.value)}
              // sx={{
              //   outline: "none",
              //   minWidth: 500,
              //   maxWidth: "100%",
              //   overflow: "auto !important",
              //   maxHeight: 300,
              //   padding: 10,
              //   resize: "none",
              //   marginBottom: 20,
              //   border:"1px solid rgb(133, 133, 133)",
              //   borderRadius: 4,
              // }}
            />
            <div className="modalFooter">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  gap: "10px",
                }}
              >
                <Button
                  variant="contained"
                  // onClick={sendSupportMessage}
                  disabled={!supportText}
                  sx={{
                    backgroundColor: "#5335CA",
                    ":hover": {
                      backgroundColor: "#5335CA",
                    },
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setOpenSupportModal(!openSupportModal)}
                  sx={{
                    backgroundColor: "#939393",
                    ":hover": {
                      backgroundColor: "#888888",
                    },
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </FormGroup>
        </Box> */}
        <HelpAndSupportModal
          close={() => setOpenSupportModal(!openSupportModal)}
        />
      </Modal>
      <LearnerInfoModal
        showTraining={false}
        close={() => setShowLearnerInfoModal(false)}
        open={showLearnerInfoModal}
        userData={data}
      />

      <LearnerTrainingModal
        showTraining={false}
        close={() => setShowLearnerTrainingModal(false)}
        open={showLearnerTrainingModal}
        userData={learnerData}
      />
    </Fragment>
  );
};

export default withRouter(NavBar);
