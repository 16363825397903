import React, { useState, useContext, useEffect } from "react";
import "./OTJContainer.css";
import {
  Avatar,
  Box,
  Card,
  Typography,
  Skeleton,
  CardContent,
  Stack,
  Divider,
  IconButton,
} from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { makeStyles } from "@mui/styles";
import { AppContext } from "../../context/appContext";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import InfoIcon from "@mui/icons-material/Info";
import LearnerInfoModal from "../LearnerInfoModal/LearnerInfoModal";
import { getHours } from "../../utils/functions";
import LearnerGraphDrawer from "../LearnerGraphDrawer/LearnerGraphDrawer";
import axios from "axios";
// import Skeleton from '@mui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  divStyle: {
    display: "flex",
  },
  divStyle2: {
    display: "flex",
    justifyContent: "space-between",
  },
  divStylee: {
    display: "flex",
    padding: 10,
    justifyContent: "space-between",
  },
  field: {
    marginBottom: 20,
  },
  formStylee: {
    display: "flex",
    marginBottom: 20,
    marginTop: 10,
    justifyContent: "space-between",
  },
  boxStyle: {
    marginTop: 20,
    padding: 40,
    borderRadius: 4,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    "@media (max-width: 768px)": {
      padding: 20,
    },
  },
  boxStylePie: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    width: "100%",
  },
  boxStyleLine: {
    marginTop: 20,
    padding: 40,
    borderRadius: 4,
    background: "white",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    width: "100%",
  },
  cardStyle: {
    marginTop: 20,
    borderRadius: 4,
  },
  profileCard: {
    background: "#5335CA",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 4,
    padding: "20px",
  },
  profileCardText: {
    fontSize: 16,
    color: "white",
  },
  profileCardSubText: {
    fontSize: 12,
    color: "white",
  },
  text1: {
    marginTop: "50px",
    marginLeft: "25px",
  },
  icons: {
    height: 14,
    widht: 14,
    color: "#5335CA",
  },
  button: {
    background: "#5335CA",
    "&:hover": {
      background: "#5335CA",
    },
  },
  typoText1: {
    fontFamily: "Poppins",
    fontSize: 9,
    fontWeight: 600,
  },
  qIcon: {
    height: "22.58px",
    top: "6px",
    width: "20px",
    position: "relative",
  },
  buttonn: {
    background: "#939393",
    marginRight: 10,
    "&:hover": {
      background: "#888888",
    },
  },
  form: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  label: {
    padding: "inherit",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  skeleton: {
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0px 4px 8px rgba(140, 136, 136, 0.15)",
    borderRadius: 4,
    marginTop: 20,
  },
}));

const OTJContainer = (props) => {
  const classes = useStyles();
  // data, setData, handleToggle, updateMessage, getUserData
  const { data } = useContext(AppContext);
  const [showLearnerInfoModal, setShowLearnerInfoModal] = useState(false);
  const [showLearnerGraphDrawer, setShowLearnerGraphDrawer] = useState(false);
  const [response, setResponse] = useState([]);
  const [chartData, setChartData] = useState([]);
  // console.log("response::", response);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { userData } = props;

  const differencehrs = getHours(
    userData?.progress?.currentTarget - userData?.progress?.cureentProgress
  ).hours;
  const differenceMin = getHours(
    userData?.progress?.currentTarget - userData?.progress?.cureentProgress
  ).minutes;

  // const SubmittedHrs = getHours(userData.progress.cureentProgress).hours;
  // const SubmittedMin = getHours(userData.progress.cureentProgress).hours;

  // console.log("differencehrs", differencehrs, differenceMin);
  // Fetch chart data when learner ID is available
  useEffect(() => {
    if (userData?._id) {
      const fetchChartData = async () => {
        try {
          setLoading(true);
          const res = await axios.get(`/learner/chart-data/${userData._id}`, {
            config: { handlerEnabled: true },
          });
          const data = res.data.data;
          setResponse({
            ...res.data,
            currentMonth: { ...res.data.currentMonth }, // Ensure new reference
          });
          setChartData(data?.weeklyData || []);
          setLoading(false);
        } catch (e) {
          setError(e.message);
          setLoading(false);
        }
      };

      fetchChartData();
    }
  }, [userData]);

  const getBackGroundColor = () => {
    // console.log("userData.status", userData.status);
    // userData.status === "On"
    // ? "rgba(124, 189, 26, 0.1)"
    // : "rgba(189, 26, 65, 0.1)",

    if (userData.targetStatus === "On") {
      return "rgba(124, 189, 26, 0.1)";
    } else if (userData.targetStatus === "Below") {
      return "rgba(189, 124, 26, 0.1)";
    } else {
      return "rgba(189, 26, 65, 0.1)";
    }
  };

  return (
    <>
      <Box className={classes.cardStyle}>
        {data && userData && (
          <Card>
            <CardContent className="card-content">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h5" component="h3" gutterBottom>
                  Off-The-Job Hours
                </Typography>
                <IconButton
                  onClick={() =>
                    setShowLearnerGraphDrawer(!showLearnerGraphDrawer)
                  }
                  color="secondary"
                  aria-label="reset"
                >
                  <BarChartIcon sx={{ cursor: "pointer" }} title="Close" />
                </IconButton>
              </Stack>

              <Stack
                sx={{
                  height: 40,
                  // borderLeft: "4px solid red",
                  // background: "#E5E5E5",
                  width: "100%",
                  marginTop: 2,
                  marginBottom: 1,
                  borderRadius: 1,
                  backgroundColor: "rgba(21, 101, 192, 0.08)",
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Typography variant="h5" component="h3" textAlign="center">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end", // Align items to the bottom
                      justifyContent: "center",
                    }}
                  >
                    {userData?.progress?.cureentProgress >=
                      userData?.progress?.currentTarget && (
                      <CheckBoxOutlinedIcon
                        sx={{ color: "green", marginRight: "8px" }} // Add margin for spacing
                        title="On target"
                      />
                    )}
                    {userData?.progress?.cureentProgress <
                      userData?.progress?.currentTarget && (
                      <WarningAmberOutlinedIcon
                        sx={{ color: "red", marginRight: "8px" }} // Add margin for spacing
                        title="On target"
                      />
                    )}
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      {/* {getHours(userData.progress.cureentTarget).hours} -
                      {getHours(userData.progress.cureentProgress).hours}
                      <span style={{ fontSize: "12px", marginRight: "4px" }}>
                        hr{" . "}
                      </span>
                      {getHours(userData.progress.cureentTarget).hours} -
                      {getHours(userData.progress.cureentProgress).minutes}
                      <span style={{ fontSize: "12px" }}>min</span> */}
                      {differencehrs}{" "}
                      <span style={{ fontSize: "12px", marginRight: "4px" }}>
                        hr{" . "}
                      </span>
                      {differenceMin}{" "}
                      <span style={{ fontSize: "12px" }}>min</span>
                    </div>
                  </div>
                </Typography>

                {/* <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: "100%" }}
                /> */}
                <Typography sx={{ p: 1 }}>
                  {" "}
                  {userData?.progress?.cureentProgress <
                  userData?.progress?.currentTarget
                    ? "Below Target"
                    : "On Target"}
                </Typography>
              </Stack>

              <Stack
                sx={{
                  height: 90,
                  // borderLeft: "4px solid red",
                  // background: "#E5E5E5",
                  backgroundColor: "rgba(21, 101, 192, 0.08)",

                  width: "100%",
                  marginTop: 2,
                  marginBottom: 1,
                  borderRadius: 1,
                }}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {loading && null}
                  {error && <p>Error: {error}</p>}
                  {response && (
                    <>
                      {/*
                      <Typography component="p" variant="p">
                        {response.currentMonth?.hours} hrs
                      </Typography> */}{" "}
                      <Typography component="h3" variant="p">
                        {response.currentMonth?.month}
                      </Typography>
                      <Typography component="p" variant="p">
                        {getHours(userData?.progress?.cureentProgress)?.hours}

                        <span style={{ fontSize: "12px" }}>hr{" . "}</span>
                        {getHours(userData?.progress?.cureentProgress)?.minutes}
                        <span style={{ fontSize: "12px" }}>min</span>
                      </Typography>
                      <Typography component="p" variant="p">
                        Submitted
                      </Typography>
                    </>
                  )}{" "}
                </Stack>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: "100%" }}
                />
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Typography component="h3" variant="p">
                    Forecast
                  </Typography>
                  {/* <Typography component="h3" variant="p">
                    {userData?.monthlyHourMinutes.length} weeks
                  </Typography> */}
                  <Typography component="p" variant="p">
                    {getHours(userData.totalSeconds).hours}

                    <span style={{ fontSize: "12px" }}>hr{" . "}</span>
                    {getHours(userData.totalSeconds).minutes}
                    <span style={{ fontSize: "12px" }}>min</span>
                  </Typography>
                  <Typography component="p" variant="p">
                    Targeted
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )}
      </Box>

      <LearnerGraphDrawer
        showTraining={false}
        close={() => setShowLearnerGraphDrawer(false)}
        open={showLearnerGraphDrawer}
        userData={data}
      />
    </>
  );
};

export default OTJContainer;
